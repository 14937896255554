export const Config = {
  base_url: "REACT_APP_BASE_URL",
  wa_site_url: "https://www.wealthyaffiliate.com",
  jaaxy_site_url: "https://my.jaaxy.com/",
  apiUrl: "REACT_APP_API_URL",
  apiWs: "REACT_APP_WS_URL",
  profileImageBaseUrl: "REACT_APP_PROFILE_IMAGE_URL",
  amqUrl: "REACT_APP_AMQ_URL",
  amqTopic: "REACT_APP_AMQ_TOPIC",
  sentryDSN: "REACT_APP_SENTRYDSN",
  welcomeVideoWistiaHash: "REACT_APP_WISTIA_WELCOME_HASH",
  welcomeVideoThumbnail: "REACT_APP_WELCOME_THUMBNAIL",
  welcomeInitialPromoFlag: "REACT_APP_PROMO_FLAG",
  welcomePromodate: "REACT_APP_PROMO_DATE",
  recaptchaKey: "REACT_APP_RECAPTCHA_KEY",
  googleMapsApiKey: "REACT_APP_GOOGLE_MAPS_API_KEY",
  googleAnalytics4Key: "REACT_APP_MEMBERS_GA4_ID",
  facebookLoginApiKey: "REACT_APP_FACEBOOK_LOGIN_API_KEY",
  ustreamChannel: "REACT_APP_USTREAM_CHANNEL",
  classFileUploadMaxLimit: "CLASS_FILE_UPLOAD_MAX_LIMIT",
  redactorAttachmentUploadMaxLimit: "2097152",
  blackfriday_promo_start: "BLACKFRIDAY_PROMO_START",
  blackfriday_promo_end: "BLACKFRIDAY_PROMO_END",
  blackfriday_promo_final: "BLACKFRIDAY_PROMO_FINAL",
  blackfriday_upto_off_price: 689,
  blackfriday_learnmore_date: "25 November 2021 13:00 PST",
  blackfriday_rewind_price: 299,
  optimizeId: "REACT_APP_GUEST_OPTIMIZE_ID",
  buildEnvironment: "BUILD_ENVIRONMENT",
  releaseVersion: "REACT_APP_RELEASE_VERSION",
  uxTopnavAssetUrl: "UX_TOPNAV_ASSET_URL",
  highlight_message_time: 1800,
  initial_number_of_comments_loaded: 2,
  development_branch: "REACT_APP_DEVELOPMENT_BRANCH",
  show_date_in_title_threshold: 24,
  stripe_pk: "REACT_APP_STRIPE_PK",
  starter_default_price_point: "yearly",
  paid_user_default_price_point: "yearly",
  wa_openai_result_id: "WEALTHY_AFFILIATE_OPENAI_NICHE_RESULT_ID",
  outline_section_wordcount: 100,
  article_section_wordcount: 250,
  hubBuildVideoHash: "REACT_APP_HUB_BUILD_VIDEO_HASH",
  hubBuildVideoThumbnail: "REACT_APP_HUB_BUILD_VIDEO_THUMBNAIL",
  hubBuildVideoDuration: "REACT_APP_HUB_BUILD_VIDEO_DURATION",
  hubBuildStarterVideoHash: "REACT_APP_HUB_BUILD_STARTER_VIDEO_HASH",
  hubBuildCompleteVideoHash: "REACT_APP_HUB_BUILD_COMPLETE_VIDEO_HASH",
  upgradeVideoHash: "REACT_APP_UPGRADE_VIDEO_HASH",
  allowCommentExpiration: "ALLOW_COMMENTS_EXPIRATION",
  starterLiveChat: "ON",
  apiCIUrl: "REACT_APP_CI_APP_URL",
};
